import type { TImage, TISODateTime } from '@/infra/types/common/base';
import type { TCurrencyCode, TMoneyAmount } from '@/types/common';

export type TGuestPriceDetail = {
  gratuityAmount: number;
  hotelPrice: {
    amount: number;
    feeAmount: number;
    taxAmount: number;
  };
  originalAmount: number;
  portFees: number;
  taxAmount: number;
  tripInsurance: number;
} & TMoneyAmount;

export enum DiscountType {
  PNSV = 'PNSV',
}

export type TBookingPriceDetailDiscount = {
  learnMoreTooltip?: string;
  name?: string;
  perks?: string;
  perksPriority?: string;
  perksSubheading?: string;
  priceTypeText: DiscountType | string;
} & TMoneyAmount;

export type TBookingPriceDetail = {
  currencyCode: TCurrencyCode;
  discount: number;
  discountList: TBookingPriceDetailDiscount[];
  fullPayAmount: number;
  gratuities: string;
  gratuityAmount: number;
  gratuityPerGuestPerNight: number;
  originalAmount: number;
  paidAmount: number;
  portFees: number;
  taxAmount: number;
  totalDue: number;
  tripInsurance: number;
} & TMoneyAmount;

export type TAddOnPriceDetails = {
  taxAmount: 0;
} & TMoneyAmount;

export type TCabinInvoice = {
  addOnPriceDetails: TAddOnPriceDetails;
  bookingPriceDetails: TBookingPriceDetail;
  cabinAttributes: string[];
  cabinNumber: string;
  cabinSide: string;
  cabinTypeCode: string;
  deck: `${number}`;
  fullPayPrice: Partial<TBookingPriceDetail>;
  guestCount: number;
  guestPriceDetails: TGuestPriceDetail[];
  images: TImage[];
  isJoker?: boolean;
  isVip?: boolean;
  landscapeGallery: TImage[];
  maxOccupancy: number;
  name: string;
  position?: string;
  shipLocationImage: TImage;
  status: string;
  tags: unknown[];
  whatsIncluded: string[];
  whatsIncludedHeadline: string;
  zone: string;
  zoneDescription: string;
};

export type TPaymentSchedule = {
  depositAmount:
    | {
        amount: number;
        dueDate: TISODateTime;
      }
    | Record<string, never>;
  finalAmount: {
    amount: number;
    dueDate: TISODateTime;
  };
};
