import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { getDatesRange } from '@/helpers/util/dateUtil';
import { useAppSelector } from '@/store';

import type { TPrice } from '../types';

import useGetNavigationSteps from '../useGetNavigationSteps';
import { Label } from './Label';
import { Step } from './Step';

import './Progress.scss';

type ProgressProps = { price?: TPrice };

export const Progress = ({ price }: ProgressProps) => {
  const { debarkDate, embarkDate, name: voyageName } = useAppSelector(selectSailingData) || {};
  const voyageDates = useMemo(() => getDatesRange({ from: embarkDate, to: debarkDate }), [embarkDate, debarkDate]);

  const { activeStepIndex, steps } = useGetNavigationSteps({ price, voyageDates, voyageName });

  return (
    <ol className="Progress -container -stepMask" id="ProgressBar">
      {steps.map((step) => (
        <Step
          activeStep={activeStepIndex}
          id={step.index}
          key={step.index}
          label={step.label}
          maxStep={isEmpty(steps) ? 0 : steps.length}
          uri={step.uri}
        >
          <Label activeStep={activeStepIndex} isLoading={step.isLoading} label={step.label} subLabel={step.subLabel} />
        </Step>
      ))}
    </ol>
  );
};
