import useCalcMainValues from '@/containers/VoyagePlanner/Summary/FareBreakdown/hooks/useCalcMainValues';
import { useGetIsTaxIncluded } from '@/helpers/pricing/lib/tax';

const useFlowNavigationPrice = () => {
  const taxInclusive = useGetIsTaxIncluded();
  const { currencyCode, grandTotalAmount } = useCalcMainValues();

  return {
    amount: grandTotalAmount,
    currencyCode,
    taxInclusive,
  };
};

export default useFlowNavigationPrice;
