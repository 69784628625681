import { useSearchParams } from 'next/navigation';

import { isEqual } from 'lodash';

import { selectAccessKeyPromoCode } from '@/ducks/accessKeys';
import { selectLookup } from '@/ducks/common/selectors';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { parseQueryString } from '@/helpers/util';
import useStrictMemo from '@/hooks/useStrictMemo';
import { useAppSelector } from '@/store';

const useParsedData = () => {
  const voyageSearch = useAppSelector(
    (state) => state?.voyagePlanner?.itineraryResults?.voyageSearch?.data || {},
    isEqual,
  );
  const accessKey = useAppSelector(selectAccessKeyPromoCode);
  const configServiceData = useAppSelector(selectSettings, isEqual);
  const lookup = useAppSelector(selectLookup, isEqual);
  const searchParams = useSearchParams();

  return useStrictMemo(
    () => ({
      ...parseQueryString({
        configServiceData,
        lookup,
        searchParams,
        voyageSearch,
      }),
      accessKey,
    }),
    [voyageSearch, configServiceData, lookup, searchParams, accessKey],
  );
};

export default useParsedData;
