import type { TUIResourceById } from '@/ducks/common/resources';
import type { TCurrencyCode } from '@/infra/types/common';

export enum STEP_INDEX {
  None = 0,
  ChooseVoyage = 1,
  ChooseCabin = 2,
  AddOns = 3,
  Summary = 4,
  Payment = 5,
}

export type NavigationStepProps = {
  charterId: string;
  isLoading: boolean;
  taxes: string;
} & UseGetNavigationStepsProps;

export type UseGetNavigationStepsProps = {
  price?: TPrice;
  voyageDates: string;
  voyageName?: string;
};

export type TPrice = {
  amount: number;
  currencyCode: TCurrencyCode;
  taxInclusive: boolean;
};

export type NavigationStepSetting = {
  isLoading: boolean;
  label: {
    desktop: TUIResourceById;
    mobile: TUIResourceById;
    renderer: React.ElementType;
    screenReader?: TUIResourceById;
  };
  subLabel: {
    isVisible: boolean;
    renderer: React.ElementType;
  };
  uri: () => string;
};
