import { usePathname, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import type { TCurrencyCode } from '@/infra/types/common';

import Price from '@/components/elements/Price';
import UIResource from '@/components/UIResource';
import { useUIResourcePool } from '@/ducks/common/resources';
import { pickAnyFromSearchParams } from '@/ducks/pages/navigation';
import { selectCabinInvoiceCabinName, selectCalculateInvoiceIsLoading } from '@/ducks/pages/summary/selectors';
import { routes } from '@/ducks/routes';
import useGetChosenSubCategoryCode from '@/ducks/voyageInfo/category/useGetChosenSubCategoryCode';
import { selectIsSailingDataEmpty } from '@/ducks/voyageInfo/sailing/selectors';
import { getSessionStorageValue } from '@/helpers/util';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { useIsMobile } from '@/hooks/useIsDevice';
import { useVWOFlag } from '@/hooks/useVWOFeature';
import { selectCabinByCode } from '@/reducers/voyagePlanner/chooseCabin/cabinCategories/selectors';
import { useAppSelector } from '@/store';
import { VWOFlag } from '@/types/vwo';

import { type NavigationStepSetting, STEP_INDEX, type UseGetNavigationStepsProps } from './types';

const useGetNavigationSteps = ({ price, voyageDates, voyageName }: UseGetNavigationStepsProps) => {
  const pathname = usePathname();
  const isAddOnsEnabled = useVWOFlag(VWOFlag.ADDONS);

  const categoryCodeFromURL = useGetChosenSubCategoryCode();
  const categoryNameFromURL = useAppSelector((state) => selectCabinByCode(state, categoryCodeFromURL)?.name);
  const categoryNameFromInvoice =
    useAppSelector(selectCabinInvoiceCabinName) ?? getSessionStorageValue('invoiceData')?.cabinInvoices?.[0]?.name;
  const cabinCategoryName = categoryNameFromInvoice || categoryNameFromURL;

  const isMobile = useIsMobile();
  const { labelExclDesktop, labelExclMobile, labelInclDesktop, labelInclMobile } = useUIResourcePool(UI_RESOURCE_POOL);

  const taxes = useMemo(() => {
    const label = isMobile
      ? price?.taxInclusive
        ? labelInclMobile
        : labelExclMobile
      : price?.taxInclusive
        ? labelInclDesktop
        : labelExclDesktop;

    return label?.trim();
  }, [isMobile, price?.taxInclusive, labelInclMobile, labelExclMobile, labelInclDesktop, labelExclDesktop]);

  const params = useSearchParams();
  const charterId = params.get('charterId');

  const isSailingDataEmpty = useAppSelector(selectIsSailingDataEmpty);
  const isInvoiceEmpty = useAppSelector(selectCalculateInvoiceIsLoading);

  const steps = [
    { index: STEP_INDEX.ChooseVoyage, path: routes.planner.preCheckOut.path, step: getChooseVoyageStep },
    { index: STEP_INDEX.ChooseCabin, path: routes.planner.chooseCabin.path, step: getChooseCabinStep },
    { index: STEP_INDEX.AddOns, path: routes.planner.addOns.path, step: getAddOnsStep },
    { index: STEP_INDEX.Summary, path: routes.planner.summary.path, step: getSummaryStep },
    { index: STEP_INDEX.Payment, path: routes.planner.payment.path, step: getPaymentStep },
  ];

  if (!isAddOnsEnabled) {
    steps.splice(2, 1);
  }

  const activeStepIndex = steps.find(({ path }) => pathname.startsWith(path))?.index || STEP_INDEX.None;

  const navigationSettingProps: NavigationSettingProps = {
    activeStepIndex,
    cabinCategoryName,
    charterId,
    isInvoiceEmpty,
    isSailingDataEmpty,
    price,
    taxes,
    voyageDates,
    voyageName,
  };

  return { activeStepIndex, steps: steps.map(({ index, step }) => ({ index, ...step(navigationSettingProps) })) };
};

type NavigationSettingProps = {
  activeStepIndex: STEP_INDEX;
  cabinCategoryName?: string;
  charterId: null | string;
  isInvoiceEmpty: boolean;
  isSailingDataEmpty: boolean;
  taxes: string;
} & UseGetNavigationStepsProps;

type GetCVPStepSubLabelProps = { voyageDates: string; voyageName?: string };

const UI_RESOURCE_POOL = {
  labelExclDesktop: 'MainNav.Summary.tax.excluding.desktop',
  labelExclMobile: 'MainNav.Summary.tax.excluding.mobile',
  labelInclDesktop: 'MainNav.Summary.tax.including.desktop',
  labelInclMobile: 'MainNav.Summary.tax.including.mobile',
} as const;

const DefaultLabelRenderer = ({ children }: { children: React.ReactNode }) => children;

const splitSubLabel = (subLabel?: string) => {
  if (typeof subLabel === 'string' && subLabel.indexOf(' ') !== -1) {
    // Search for the nearest to the specified distance space index (80% from the string start)
    let index = Math.floor(subLabel.length * 0.8);
    const space1Index = index - subLabel.substring(0, index).lastIndexOf(' ');
    const space2Index = subLabel.substring(index).indexOf(' ');
    if (space1Index >= space2Index) {
      index -= space1Index;
    } else {
      index += space2Index;
    }

    return [subLabel.substring(0, index), subLabel.substring(index)];
  }

  return [subLabel, ''];
};

const GetCVPStepSubLabel = (props: GetCVPStepSubLabelProps) => {
  const { voyageDates, voyageName } = props;
  const [part1, part2] = splitSubLabel(voyageName);

  return (
    <span className="voyageTitle">
      <span className="voyageTitle1">{part1}</span>
      <span className="voyageTitle2">{part2 && <span>&nbsp;{part2}</span>}</span>
      <span className="voyageDate">{voyageDates}</span>
    </span>
  );
};

const getChooseVoyageStep = (props: NavigationSettingProps): NavigationStepSetting => ({
  isLoading: props.activeStepIndex >= STEP_INDEX.ChooseVoyage && props.isSailingDataEmpty,
  label: {
    desktop: { defaultMessage: 'Your voyage', id: 'MainNav.Summary.yourVoyage.desktop' },
    mobile: { defaultMessage: 'Voyage', id: 'MainNav.Summary.yourVoyage.mobile' },
    renderer: DefaultLabelRenderer,
    screenReader: { id: 'MainNav.Summary.yourVoyage.screenReader', values: { chosenVoyage: props.voyageName } },
  },
  subLabel: {
    isVisible: props.activeStepIndex >= STEP_INDEX.ChooseVoyage,
    renderer: () => <GetCVPStepSubLabel voyageDates={props.voyageDates} voyageName={props.voyageName} />,
  },
  uri: () =>
    `${routes.planner.preCheckOut.path}?${new URLSearchParams(pickAnyFromSearchParams({ withCharter: true, withChooseSailing: true, withFilters: true }))}`,
});

const getChooseCabinStep = (props: NavigationSettingProps): NavigationStepSetting => ({
  isLoading: props.activeStepIndex === STEP_INDEX.Summary && (props.isSailingDataEmpty || props.isInvoiceEmpty),
  label: {
    desktop: { defaultMessage: 'Choose cabin', id: 'MainNav.ChooseCabin.name.desktop' },
    mobile: { defaultMessage: 'Cabins', id: 'MainNav.ChooseCabin.name.mobile' },
    renderer: DefaultLabelRenderer,
  },
  subLabel: {
    isVisible: props.activeStepIndex > STEP_INDEX.ChooseCabin,
    renderer: () => <>{props.cabinCategoryName}</>,
  },
  uri: () =>
    `${routes.planner.chooseCabin.path}?${new URLSearchParams(
      pickAnyFromSearchParams({ withCharter: true, withChooseSailing: true, withFilters: true }),
    )}`,
});

const getAddOnsStep = (_: NavigationSettingProps): NavigationStepSetting => ({
  isLoading: false,
  label: {
    desktop: { defaultMessage: 'Add-ons', id: 'MainNav.AddOns.name.desktop' },
    mobile: { defaultMessage: 'Add-ons', id: 'MainNav.AddOns.name.mobile' },
    renderer: DefaultLabelRenderer,
  },
  subLabel: { isVisible: true, renderer: () => <UIResource id="MainNav.AddOns.subLabel" /> },
  uri: () =>
    `${routes.planner.addOns.path}?${new URLSearchParams(
      pickAnyFromSearchParams({ withCharter: true, withChooseSailing: true, withFilters: true }),
    )}`,
});

const getSummaryStep = (props: NavigationSettingProps): NavigationStepSetting => {
  const voyagePrice = props.price?.amount || 0;
  const currencyCode = getSymbolFromCurrenciesData(props.price?.currencyCode);
  const hasPrice = voyagePrice > 0;

  const priceRender = hasPrice ? (
    <Price amount={voyagePrice} currencyCode={props.price?.currencyCode as TCurrencyCode} />
  ) : null;

  return {
    isLoading: props.activeStepIndex === STEP_INDEX.Summary && (props.isSailingDataEmpty || props.isInvoiceEmpty),
    label: {
      desktop: { defaultMessage: 'Voyage Details', id: 'MainNav.Summary.voyageDetails.desktop' },
      mobile: { defaultMessage: 'Details', id: 'MainNav.Summary.voyageDetails.mobile' },
      renderer: DefaultLabelRenderer,
      screenReader: {
        id: hasPrice
          ? 'MainNav.Summary.voyageDetailsWithPrice.screenReader'
          : 'MainNav.Summary.voyageDetails.screenReader',
        values: { currencyCode, price: voyagePrice, taxes: props.taxes },
      },
    },
    subLabel: {
      isVisible: true,
      renderer: () =>
        props.activeStepIndex >= STEP_INDEX.Summary && (
          <>
            {priceRender}
            {props.taxes && <> ({props.taxes})</>}
          </>
        ),
    },
    uri: () => routes.planner.summary.path,
  };
};

const getPaymentStep = (_: NavigationSettingProps): NavigationStepSetting => ({
  isLoading: false,
  label: {
    desktop: { defaultMessage: 'Payment', id: 'MainNav.Payment.name.desktop' },
    mobile: { defaultMessage: 'Payment', id: 'MainNav.Payment.name.mobile' },
    renderer: DefaultLabelRenderer,
  },
  subLabel: { isVisible: false, renderer: () => <></> },
  uri: () => routes.planner.payment.path,
});

export default useGetNavigationSteps;
