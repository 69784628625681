import { useSearchParams } from 'next/navigation';

import { ChooseCategorySearchParams } from './searchParams';

const useGetChosenSubCategoryCode = () => {
  const searchParams = useSearchParams();
  return searchParams.get(ChooseCategorySearchParams.chosenSubCategoryCode)?.toUpperCase();
};

export default useGetChosenSubCategoryCode;
