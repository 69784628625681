'use client';

import useFlowNavigationPrice from '@/containers/VoyagePlanner/Summary/hooks/useFlowNavigationPrice';

import { ProgressBar } from './ProgressBar';

const FlowNavigation = () => {
  const price = useFlowNavigationPrice();

  // TODO: Simplify ProgressBar component
  return <ProgressBar price={price} />;
};

export default FlowNavigation;
