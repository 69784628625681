import { createSelector } from 'reselect';

import { DEFAULT_CURRENCY_CODE } from '@/constants/settings';
import { selectAddOnsTotalPrice } from '@/ducks/addOns/selectors';
import { useMNVV } from '@/ducks/mnvv/hooks';
import {
  selectCabinInvoices,
  selectCalculateInvoiceIsLoading,
  selectIsPayInFullPromoApplied,
  selectIsPayInFullPromoAvailable,
  selectPaymentSchedule,
  selectPriceBreakdown,
} from '@/ducks/pages/summary/selectors';
import useStrikethrough from '@/ducks/programs/strikethrough/useStrikethrough';
import { selectHasIncludedGratuities, selectIsVoyageProtectionAdded } from '@/ducks/travelParty/selectors';
import { getGrandTotal } from '@/helpers/data/mappers/Summary';
import useParsedData from '@/hooks/useParsedData';
import { DiscountType } from '@/infra/types/cabin';
import { useAppSelector } from '@/store';

const selectValues = createSelector(
  [
    selectCabinInvoices,
    selectCalculateInvoiceIsLoading,
    selectPaymentSchedule,
    selectIsPayInFullPromoApplied,
    selectIsPayInFullPromoAvailable,
    selectPriceBreakdown,
    selectIsVoyageProtectionAdded,
    selectHasIncludedGratuities,
    selectAddOnsTotalPrice,
    (_, isMNVV: boolean) => isMNVV,
    (_, __, strikethroughValue: number) => strikethroughValue,
  ],
  (
    cabinInvoices,
    invoiceIsLoading,
    paymentSchedule,
    isPayInFullPromoApplied,
    isPayInFullPromoAvailable,
    invoiceData,
    isVoyageProtectionAdded,
    hasIncludedGratuities,

    addOnsTotalPrice,
    isMNVV,
    strikethroughValue,
  ) => {
    const isLoading = invoiceIsLoading;

    const {
      cabins,
      currencyCode = DEFAULT_CURRENCY_CODE,
      deposit,
      discountList: initialDiscountList,
      originalAmount: initialOriginalAmount = 0,
      paidAmount,
      sailorsAmount,
    } = invoiceData || {};
    const cabin = cabins?.[0];
    const cabinInvoice = cabinInvoices?.[0];

    const insuranceAmount =
      cabinInvoice?.[isPayInFullPromoApplied ? 'fullPayPrice' : 'bookingPriceDetails']?.tripInsurance ?? 0;

    const tripInsurance = isVoyageProtectionAdded ? insuranceAmount : 0;

    const fullPayGrandTotalAmount = getGrandTotal(cabinInvoice, 'fullPayPrice');
    const bookingPriceGrandTotalAmount = getGrandTotal(cabinInvoice, 'bookingPriceDetails');

    const grandTotalAmount =
      (isPayInFullPromoApplied ? fullPayGrandTotalAmount : bookingPriceGrandTotalAmount) +
      tripInsurance -
      (!hasIncludedGratuities ? (cabinInvoice?.bookingPriceDetails?.gratuityAmount ?? 0) : 0);

    const firstPaymentZero = paymentSchedule?.[0]?.depositAmount?.amount === 0;
    const isDuePaymentAvailable = !isPayInFullPromoApplied && (deposit?.value !== 0 || (isMNVV && firstPaymentZero));

    const taxesAndFeesAmount = cabin?.items?.taxes?.value;
    const totalStrikethroughAwareAmount = grandTotalAmount + strikethroughValue;
    let originalTotalAmount =
      initialOriginalAmount +
      addOnsTotalPrice +
      (isVoyageProtectionAdded ? (cabinInvoice?.bookingPriceDetails?.tripInsurance ?? 0) : 0) -
      (!hasIncludedGratuities ? (cabinInvoice?.bookingPriceDetails?.gratuityAmount ?? 0) : 0);

    if (originalTotalAmount === grandTotalAmount) {
      originalTotalAmount = totalStrikethroughAwareAmount;
    }
    const govtFeesAmount = cabin?.items?.govtFees?.value;
    const portFeesAmount = cabin?.items?.portFees?.value;

    const gratuityAmount = hasIncludedGratuities ? (cabinInvoice?.bookingPriceDetails?.gratuityAmount ?? 0) : 0;
    const gratuityPerGuestPerNight = cabinInvoice?.bookingPriceDetails?.gratuityPerGuestPerNight ?? 0;

    const discountList = initialDiscountList?.filter((discount) => discount.priceTypeText !== DiscountType.PNSV);

    return {
      bookingPriceGrandTotalAmount,
      cabin,
      cabinInvoice,
      currencyCode,
      deposit,
      discountList,
      fullPayGrandTotalAmount,
      govtFeesAmount,
      grandTotalAmount,
      gratuityAmount,
      gratuityPerGuestPerNight,
      insuranceAmount,
      isDuePaymentAvailable,
      isLoading,
      isMNVV,
      isPayInFullPromoApplied,
      isPayInFullPromoAvailable,
      isVoyageProtectionAdded,
      originalTotalAmount,
      paidAmount,
      portFeesAmount,
      sailorsAmount,
      taxesAndFeesAmount,
      tripInsurance,
    };
  },
);

const useCalcMainValues = () => {
  const { isMNVV } = useMNVV();

  const { chosenSubCategoryCode } = useParsedData();
  const getStrikethroughForPackage = useStrikethrough();
  const strikethroughValue = getStrikethroughForPackage(chosenSubCategoryCode);

  return useAppSelector((state) => selectValues(state, isMNVV, strikethroughValue));
};

export default useCalcMainValues;
